import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { useMainContext } from './utils/context'

const WebSocketContext = createContext(null)

export const WebSocketProvider = ({ children }) => {
    const audioRef = useRef(null);
    const [muted, setMuted] = useState(true)
    const { token, storeId, cuetone } = useMainContext()
    const [orders, setOrders] = useState({
        all_order_num: 0,               // 外卖配送订单+外卖自取订单+扫码点餐订单
        wm_order_new_num: 0,            // 外卖配送订单-待发货
        wm_order_abnormal_num: 0,       // 外卖配送订单-配送异常
        wm_order_num_today: 0,          // 外卖配送订单-当日订单
        wm_order_num_all: 0,            // 外卖配送订单-总订单
        wm_take_order_num: 0,           // 外卖自取订单-制作中
        wm_take_order_num_today: 0,     // 外卖自取订单-当日订单
        wm_take_order_num_all: 0,       // 外卖自取订单-总订单
        scan_code_to_order: 0,          // 扫码点餐订单-制作中
        scan_code_to_order_today: 0,    // 扫码点餐订单-当日订单
        scan_code_to_order_all: 0,      // 扫码点餐订单-总订单
        new_order_notice: 0,            // 1:新订单通知  0:没有
        refund_order_num: 0,            // 退款/退货管理
        logistics_order_num_today: 0,   // 物流订单 当日订单
        logistics_order_num_all: 0,     // 物流订单 总订单
        logistics_order_new_num: 0,     // 物流订单 待发货
        logistics_order_abnormal_num: 0,// 物流订单 配送异常
    })

    useEffect(() => {
        // console.log('WebSocket storeId:', storeId)
        if (storeId) {
            let timer = null
            const host = ['store.dzans.cn', 'store.dev.dzans.cn'].includes(window.location.host) ? window.location.host : 'store.dev.dzans.cn'
            const ws = new WebSocket(`wss://${host}/wss?shop_id=${storeId}`)
            ws.addEventListener('message', function (res) {
                const data = JSON.parse(res.data)
                if (data.message === 'success') {
                    setOrders(data.data)
                }
            })
            ws.addEventListener('open', function () {
                console.log('WebSocket 连接打开')
                timer = setInterval(() => {
                    const message = { type: 'ping' }
                    ws.send(JSON.stringify(message))
                }, 30 * 1000)
            })
            ws.addEventListener('close', function () {
                console.log('WebSocket 连接已关闭')
                if (timer) {
                    clearInterval(timer)
                    timer = null
                }
            })
            ws.addEventListener('error', function (e) {
                console.log('WebSocket 连接出错', e)
            })
    
            return () => {
                ws.close()
                if (timer) {
                    clearInterval(timer)
                    timer = null
                }
            }
        }
    }, [storeId])

    useEffect(() => {
        // console.log(data.data)
        if (token && cuetone == 0 && orders.new_order_notice) {
            audioRef.current && audioRef.current
            .play()
            .then(() => {
                console.log('播放成功')
            })
            .catch(() => {})
        }
    }, [orders])

    useEffect(() => {
        // 兼容谷歌必须交互才能播放声音
        document
            .getElementsByTagName('body')[0]
            .addEventListener('click', bodyClick, false);
    }, [])

    const bodyClick = () => {
        setMuted(false);
        document
            .getElementsByTagName('body')[0]
            .removeEventListener('click', bodyClick, false);
    }

    return (
        <WebSocketContext.Provider value={{ orders }}>
            {children}
            <audio
                ref={audioRef}
                className="audio"
                src={require("./assets/mp3/neworders.mp3")}
                muted={muted}
            ></audio>
        </WebSocketContext.Provider>
    );
}

export const useWebSocket = () => useContext(WebSocketContext)
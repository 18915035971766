import React, { useEffect } from "react";
import { Button, Modal, List, Checkbox, Input } from "antd";
import { Get_Store_Products } from "@/graphql/products";

import Cookies from "js-cookie";
import { useQuery } from "react-apollo";
import { DeleteOutlined } from "@ant-design/icons";

import "./index.less";

function ProductSelector(props) {
  const { value, onChange, mode } = props;
  const [visible, setVisible] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [selData, setSelData] = React.useState([]);
  const [haveMore, setHaveMore] = React.useState(false);
  const [dataSoruce, setDatasoruce] = React.useState([]);
  const [name, setName] = React.useState();

  const dataRes = useQuery(Get_Store_Products, {
    fetchPolicy: "no-cache",
    variables: {
      page,
      limit: 15,
      name,
      storeId: Cookies.get("store_id"),
      product_type: 1,
    },
  });
  useEffect(() => {
    try {
      if (dataRes.data) {
        const { data, has_more_pages } = dataRes.data.product;

        const needRefresh =
          !dataSoruce.length ||
          dataSoruce.slice(-1)[0].id !== data.slice(-1)[0].id;

        if (!needRefresh) return;

        const newData = page === 1 ? data : [...dataSoruce, ...data];
        setDatasoruce(newData);
        setHaveMore(has_more_pages);
      }
    } catch (error) {
      console.log(error);
    }
  }, [dataRes.data, dataSoruce, page]);

  useEffect(() => {
    if (value) {
      setSelData(value);
    }
  }, [value]);

  return (
    <div className="Product-selector">
      <Button type="primary" onClick={() => setVisible(true)}>
        选择
      </Button>
      <Modal
        title="选择商品"
        open={visible}
        onOk={() => {
          onChange(selData);
          setVisible(false);
        }}
        onCancel={() => setVisible(false)}
        okText="确认"
        cancelText="取消"
      >
        <Input.Search
          placeholder="输入商品名称或者ID"
          enterButton="搜索"
          size="large"
          allowClear
          onSearch={(v) => {
            setPage(1);
            setName(v);
          }}
        />
        <List
          className="list-container"
          itemLayout="vertical"
          loadMore={
            haveMore && (
              <Button
                className="list-container-loadmore"
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                加载更多
              </Button>
            )
          }
        >
          {dataSoruce.map((item) => {
            return (
              <List.Item key={item.id}>
                <Checkbox
                  checked={selData.find((data) => data.id === item.id)}
                  onClick={() => {
                    if (mode === "multiple") {
                      const selIndex = selData.findIndex(
                        (data) => data.id === item.id
                      );
                      if (selIndex > -1) {
                        selData.splice(selIndex, 1);
                        setSelData([...selData]);
                      } else {
                        setSelData([
                          ...selData,
                          {
                            id: item.id,
                            name: item.name,
                          },
                        ]);
                      }
                    } else {
                      setSelData([
                        {
                          id: item.id,
                          name: item.name,
                        },
                      ]);
                    }
                  }}
                >
                  <span
                    style={{
                      display: "inline-block",
                      marginRight: 10,
                    }}
                  >
                    商品ID：{item.id}
                  </span>
                  {/* <img
                    src={item.cover.url}
                    alt=""
                    width="50"
                    height="50"
                    style={{
                      margin: "0 10px",
                    }}
                  /> */}
                  <span>商品名称：{item.name}</span>
                </Checkbox>
              </List.Item>
            );
          })}
        </List>
      </Modal>
      {value && value.length ? (
        <List
          style={{
            maxWidth: 300,
          }}
        >
          {value.map((item, index) => (
            <List.Item
              key={item.id}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  marginRight: 10,
                }}
              >
                商品ID：{item.id}
              </span>
              {/* <img
                    src={item.cover.url}
                    alt=""
                    width="50"
                    height="50"
                    style={{
                      margin: "0 10px",
                    }}
                  /> */}
              <span>商品名称：{item.name}</span>
              <Button
                type="link"
                onClick={() => {
                  value.splice(index, 1);
                  onChange(value);
                }}
              >
                <DeleteOutlined />
              </Button>
            </List.Item>
          ))}
        </List>
      ) : null}
    </div>
  );
}

export default ProductSelector;

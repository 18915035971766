import http from '../request'

// 添加子店铺
export const addSonStore = (params) => http.post('/shop/add-son-store', params)

// 子店铺列表
export const getSonStore = (params) => http.post('/shop/son-store', params)

// 解绑
export const unbindSonStore = (params) => http.post('/shop/del-store', params)

// 商家信息
export const getStoreDetail = () => http.get('/shop/store-detail')
import React, { useState, useEffect, useMemo } from "react";
import {
  Switch,
  Input,
  Select,
  Message,
  Radio,
  Cascader,
  Form,
  Row,
  Col,
  Button,
  Modal,
  InputNumber,
  Space,
  Table,
  Checkbox,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import BraftEditor from "braft-editor";
import FileUpload from "@/components/FileUpload";
import { ADD_GOODS, EDIT_GOODS, GOODS_DETAIL, USABLE_LOGISTICS, RESOURCE_IMAGE, RESOURCE_VIDEO } from "@/graphql/product";
import { Get_Store_Categorys, GET_CATEGORY } from "@/graphql/products";
import { USER_AND_LOGISTICS, SUPPLIERS_LIST } from "@/graphql/store";
import { operateImg } from "@/utils/index";
import client from "@/graphql/apollo-config";
import Cookies from "js-cookie";
import app from "@/app.json";
import md5 from "md5";
import { useLazyQuery, useQuery } from "react-apollo";
import "./index.less";
import "braft-editor/dist/index.css";

const { Option } = Select;
const { TextArea } = Input;

let ossData = {};

const NewProduct2 = (props) => {
  const [form] = Form.useForm();
  const [cover, setCover] = useState([]); // 封面
  const [video, setVideo] = useState([]); // 商家视频
  const [images, setImages] = useState([]); // 封面
  const [productCategroy, setProductCategroy] = useState([]); // 商品类目
  const [productType, setProductType] = useState("");
  const [isSpend, setIsSpend] = useState(0); // 0普通上架  1红包券商城或特推商城
  const [isSupplier, setIsSupplier] = useState(0);
  const [categoryId, setCategoryId] = useState("");
  const [OSSData, setOSSData] = useState({});
  const [showSelect, setShowSelect] = useState(false);
  const [resource, setResource] = useState([]);
  const [choose, setChoose] = useState("");
  const [status, setStatus] = useState(0); //是否上架  1是 0否
  const [ismust, setIsmust] = useState(0); //是否必点 1是 0否
  const [isAudit, setIsAudit] = useState(0); //是否上架  1是 0否
  const [deliveryType, setDeliveryType] = useState(3) // 配送方式  0:同城配送  1:自有配送  2:普通物流  3:默认
  const [isSelfDelivery, setSelfDelivery] = useState(0) // 是否自取  1是 0否
  const selfDeliveryDisabled = useMemo(() => deliveryType === 2, [deliveryType])
  const [content, setContent] = useState(); //编辑器回显值
  const [whoChoose, setWhoChoose] = useState(0); // 0视频1封面2图片
  const [logistics, setLogistics] = useState([]); // 快递类型
  // const [dishesOptions, setDishesOptions] = useState([
  //   { label: '红烧肉', value: 0 },
  //   { label: '红烧肉1', value: 1 },
  //   { label: '红烧肉2', value: 2 },
  //   { label: '红烧肉3', value: 3 },
  //   { label: '红烧肉4', value: 4 },
  //   { label: '红烧肉5', value: 5 },
  //   { label: '红烧肉6', value: 6 },
  // ])

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.location.search.slice(4)) {
      getProductInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.search]);

  const [getSuppliers, { data: supplyChainData }] = useLazyQuery(
    SUPPLIERS_LIST,
    {
      variables: {
        page: 1,
        limit: 999,
      },
      fetchPolicy: "no-cache",
    }
  );

  const categoryRes = useQuery(GET_CATEGORY, {
    fetchPolicy: "no-cache",
  });

  const init = async () => {
    try {
      const { data } = await client.query({
        query: USER_AND_LOGISTICS,
        fetchPolicy: "no-cache",
      });
      if (data) {
        setOSSData({
          ...data.oss,
        });
        ossData = data.oss;
        const u = data.user.store;
        client
          .query({
            query: USABLE_LOGISTICS,
            variables: {
              delivery_type: u.delivery_type,
            },
          })
          .then(({ data: { usablelogistics } }) => {
            setLogistics(usablelogistics);
          });
        setIsSupplier(u.open_supplier);
        setIsSpend(u.is_spend);
        setProductType(u.product_type);
        setCategoryId(u.store_category_id);
        if (u.product_type == 0) {
          getProductCategory();
        }
        if (u.open_supplier == 1) {
          getSuppliers();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getProductInfo = async () => {
    try {
      const { data } = await client.query({
        query: GOODS_DETAIL,
        variables: {
          product_id: props.location.search.slice(4),
        },
        fetchPolicy: "no-cache",
      });
      if (data && data.GoodsDetail) {
        const {specs_types, product_category, categories, ...info} = data.GoodsDetail
        //编辑器回显
        setContent(BraftEditor.createEditorState(info.content));
        setIsAudit(info.is_deposit)
        setStatus(info.status)
        setIsmust(info.is_must)
        setDeliveryType(info.delivery_type)
        setSelfDelivery(info.is_self_delivery)

        form.setFieldsValue({
          ...info,
          content: BraftEditor.createEditorState(info.content),
          is_deposit: !!info.is_deposit,
          status: !!info.status,
          is_must: !!info.is_must,
          supplier_id: info.supplier_id || '',
          is_self_delivery: !!info.is_self_delivery,
          category_ids: categories.map(t => t.id),
          specs_type: specs_types.length > 0 ? 2 : 1,
          product_coupon: !!info.product_coupon,
          product_category_id: info.product_category_id + '',
          productSpecs: specs_types.map(t => ({
            id: t.id,
            name: t.name,
            list: t.product_specs
          }))
        })

        // 处理图片
        if (info.images.length > 0) {
          let a = info.images.map((item, index) => {
            item.uid = index;
            return item;
          });
          setImages(a);
        }
        if (info.cover.url !== null) {
          operateImg(info.cover.url, "", setCover);
        }
        // 给视频加id
        if (info.video) {
          operateImg(
            info.video.url,
            info.video.url +
              "?x-oss-process=video/snapshot,t_1000,f_jpg",
            setVideo
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getProductCategory = async () => {
    try {
      const { data } = await client.query({
        query: Get_Store_Categorys,
        variables: {
          limit: 100,
          page: 1,
        },
        fetchPolicy: "no-cache",
      });
      setProductCategroy([...data.ProductCategories.data]);
    } catch (error) {
      console.error(error);
    }
  };
  // const isRepeat = (arr) => {
  //   var hash = {};
  //   for (var i in arr) {
  //     if (hash[arr[i]]) {
  //       return true;
  //     }
  //     // 不存在该元素，则赋值为true，可以赋任意值，相应的修改if判断条件即可
  //     hash[arr[i]] = true;
  //   }
  //   return false;
  // };

  // 修改图片
  const changeImages = (arr) => {
    console.log(
      arr,
      "arrarrarrarrarrarrarrarrarrarrarrarrarrarrarrarrarrarrarrarr"
    );
    form.setFieldsValue({ images: arr });
    setImages([...arr]);
  };
  const changeCover = (arr) => {
    form.setFieldsValue({ cover: arr[0] });
    setCover([...arr]);
  };
  const changeVideo = (arr) => {
    if (arr.length > 0) {
      arr[0].thumbUrl =
        arr[0].url + "?x-oss-process=video/snapshot,t_1000,f_jpg";
    }
    form.setFieldsValue({ video: arr[0] });
    setVideo([...arr]);
  };

  const productSpecs = form.getFieldValue('productSpecs')
  const [specsColumns, specsData] = useMemo(() => {
    console.log('productSpecs memo:', productSpecs)
    const columns = [
      {
        title: '规格类型',
        dataIndex: 'name',
        width: 244,
        onCell: ({rowSpan}) => {
          return {
            rowSpan
          }
        }
      },
      {
        title: '规格',
        dataIndex: 'spec_name',
        width: 244,
      },
      {
        // title: 'SKU编码',
        dataIndex: 'sku',
        width: 110,
        render: (text, record, index) => (
          <Form.Item
            key={record.key + '_sku'}
            name={['productSpecs', record.pIndex, 'list', record.index, 'sku']}
            rules={[{ required: true, message: '' }]}
            style={{ marginBottom: 0 }}
          >
            <Input placeholder="请输入" size="small" />
          </Form.Item>
        ),
        title: () => (
          <div style={{ color: '#1B1A1E' }}>
            <span style={{ color: '#E72B2B' }}>*</span>SKU编码
          </div>
        )
      },
      {
        // title: '规格价(元)',
        dataIndex: 'price',
        width: 110,
        render: (text, record, index) => (
          <Form.Item
            key={record.key + '_price'}
            name={['productSpecs', record.pIndex, 'list', record.index, 'price']}
            rules={[{ required: true, message: '' }]}
            style={{ marginBottom: 0 }}
          >
            <Input type="number" placeholder="请输入" size="small" />
          </Form.Item>
        ),
        title: () => (
          <div style={{ color: '#1B1A1E' }}>
            <span style={{ color: '#E72B2B' }}>*</span>规格价(元)
          </div>
        )
      },
      {
        // title: '库存(件)',
        dataIndex: 'stock',
        width: 110,
        render: (text, record, index) => (
          <Form.Item
            key={record.key + '_stock'}
            name={['productSpecs', record.pIndex, 'list', record.index, 'stock']}
            rules={[{ required: true, message: '' }]}
            style={{ marginBottom: 0 }}
          >
            <InputNumber min={0} placeholder="请输入" size="small" />
          </Form.Item>
        ),
        title: () => (
          <div style={{ color: '#1B1A1E' }}>
            <span style={{ color: '#E72B2B' }}>*</span>库存(件)
          </div>
        )
      },
      {
        dataIndex: 'offer_price',
        width: 110,
        render: (text, record, index) => (
          <Form.Item
            key={record.key + '_offerPrice'}
            name={['productSpecs', record.pIndex, 'list', record.index, 'offer_price']}
            style={{ marginBottom: 0 }}
          >
            <Input type="number" placeholder="请输入" size="small" />
          </Form.Item>
        ),
        title: () => (
          <div style={{ color: '#1B1A1E' }}>供货价(元)</div>
        )
      },
      {
        title: '操作',
        dataIndex: '',
        fixed: 'right',
        width: 110,
        render: (text, record, index) => <a className="add" onClick={() => delSpecs(record.pIndex, record.index)}>删除</a>,
      }
    ]
    let data = [];
    (productSpecs || []).forEach((item, index) => {
      const _list = item.list.map(({name, ...t}, i) => {
        return {
          pIndex: index,
          index: i,
          key: `productSpecs_${index}_list_${i}`,
          name: item.name,
          spec_name: name,
          ...t,
          rowSpan: i === 0 ? item.list.length : 0
        }
      })
      data = data.concat(_list)
    })
    return [columns, data]
  }, [productSpecs])
  // 规格类型-新增分类
  const addSpecs = () => {
    const a = {
      name: '',
      list: [
        {
          name: ''
        }
      ]
    }
    form.setFieldsValue({
      productSpecs: [
        ...(productSpecs || []),
        a
      ]
    })
  }
  // 删除规格
  const delSpecs = (pIndex, index) => {
    productSpecs[pIndex].list.splice(index, 1)
    form.setFieldsValue({
      productSpecs: [
        ...productSpecs
      ]
    })
  }
  // 规格类型切换 1:单品   2:多规格
  const specsTypeChange = (v) => {
    if (v == 2 && (!productSpecs || productSpecs.length === 0)) {
      addSpecs()
    }
  }

  //编辑器数据变化
  const handleEditorChange = (editorState) => {
    setContent(editorState)
    form.setFieldsValue({ content: editorState });
  };

  // 富文本图片上传阿里云,文档地址https://www.yuque.com/braft-editor/be/gz44tn#mupgqt
  const myUploadFn = (param) => {
    // useState的数据放不进来这里
    const serverURL = `https://${ossData.host}`;
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    const suffix = param.file.name.slice(param.file.name.lastIndexOf("."));
    const fileKey =
      ossData.dir +
      md5(
        `${param.file.name}${new Date().getTime()}${
          Cookies.get("store_id") || 0
        }`
      ) +
      suffix;
    fd.append("OSSAccessKeyId", ossData.accessid);
    fd.append("policy", ossData.policy);
    fd.append("Signature", ossData.signature);
    fd.append("key", fileKey);

    const successFn = (response) => {
      // 假设服务端直接返回文件上传后的地址
      // 上传成功后调用param.success并传入上传后的文件地址
      param.success({
        url: ossData.ossUrl + fileKey,
        meta: {
          id: param.id,
          title: param.file.name,
          alt: param.file.name,
          loop: false, // 指定音视频是否循环播放
          autoPlay: false, // 指定音视频是否自动播放
          controls: true, // 指定音视频是否显示控制栏
          // poster: 'http://xxx/xx.png', // 指定视频播放器的封面
        },
      });
    };

    const progressFn = (event) => {
      // 上传进度发生变化时调用param.progress
      param.progress((event.loaded / event.total) * 100);
    };

    const errorFn = (response) => {
      // 上传发生错误时调用param.error
      param.error({
        msg: "unable to upload.",
      });
    };

    xhr.upload.addEventListener("progress", progressFn, false);
    xhr.addEventListener("load", successFn, false);
    xhr.addEventListener("error", errorFn, false);
    xhr.addEventListener("abort", errorFn, false);

    fd.append("file", param.file);
    xhr.open("POST", serverURL, true);
    xhr.send(fd);
  };

  //提交
  const onSubmit = async ({ productSpecs: _productSpecs, ...values }) => {
    // console.log(_productSpecs, values)
    try {
      const specs = (_productSpecs || []).map(t => ({
        name: t.name,
        product_specs: t.list
      }))
      const params = {
        ...values,
        keywords: (values.keywords && values.keywords.length > 0) ? values.keywords.join(',') : '',
        content: values.content.toHTML(),
        video: video.length > 0 ? video[0].url.replace(`${app.oss_host}`, '') : '',
        cover: cover.length > 0 ? cover[0].url.replace(`${app.oss_host}`, '') : '',
        images: images.map((i) => i.url.replace(`${app.oss_host}`, "")),
        is_deposit: isAudit,
        is_must: ismust,
        status: status,
        delivery_type: deliveryType,
        is_self_delivery: isSelfDelivery,
        supplier_id: values.supplier_id || 0,
        first_price: values.first_price || 0,
        product_offer_price: values.product_offer_price || 0,
        product_coupon: values.product_coupon ? 1 : 0,
        specs_types: JSON.stringify(specs)
      }
      const pid = props.location.search.slice(4)
      if (pid) {
        params.product_id = pid
      }
      const { data } = await client.mutate({
        mutation: pid ? EDIT_GOODS : ADD_GOODS,
        variables: params,
        fetchPolicy: "no-cache",
      });
      if (data[pid ? 'EditGoods' : 'AddGoods']) {
        Message.success("提交成功");
        props.history.goBack();
      }
    } catch(e) {
      console.log(e)
    }
  };

  const getList = async (i) => {
    if (categoryId == "") {
      Message.info("请先选择商品类目");
    } else {
      if (i == 1 || i == 2) {
        try {
          const { data } = await client.query({
            query: RESOURCE_IMAGE,
            variables: {
              type: 0,
              limit: 100,
              page: 1,
              store_category_id: categoryId,
            },
            fetchPolicy: "no-cache",
          });
          setResource(data.resources.data);
        } catch (error) {}
      } else {
        try {
          const { data } = await client.query({
            query: RESOURCE_VIDEO,
            variables: {
              type: 1,
              limit: 100,
              page: 1,
              store_category_id: categoryId,
            },
            fetchPolicy: "no-cache",
          });
          setResource(data.resources.data);
        } catch (error) {}
      }
      setWhoChoose(i);
      setShowSelect(true);
    }
  };
  const onChecked = (e) => {
    setChoose(e.target.value);
  };

  const handleOk = () => {
    if (choose !== "") {
      let a = {};
      if (whoChoose == 0) {
        a.url = choose;
        a.uid = new Date().getTime();
        a.name = new Date().getTime();
        video.push(a);
        changeVideo(video);
      } else if (whoChoose == 1) {
        a.url = choose;
        a.uid = new Date().getTime();
        a.name = new Date().getTime();
        cover.push(a);
        changeCover(cover);
      } else {
        a.url = choose;
        a.uid = new Date().getTime();
        a.name = new Date().getTime();
        images.push(a);
        changeImages(images);
      }
    }
    setShowSelect(false);
  };

  const changeSwi = (checked) => {
    let check = checked ? 1 : 0;
    setStatus(check);
  };
  const changeSwitwo = (checked) => {
    let check = checked ? 1 : 0;
    setIsmust(check);
  };

  const changeAudit = (checked) => {
    let check = checked ? 1 : 0;
    setIsAudit(check);
  };

  return (
    <Form form={form} onFinish={onSubmit} colon={false} labelAlign="left">
      {(data) => (<div className="new-product-wrapper">
        <div className="product-info new-product-form">
          <Row>
            <Col span={24}>
              <Row>
                <Col span={8}>
                  <Form.Item label="SPU编码" name="spu">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="商品名称"
                    labelAlign="right"
                    name="name"
                    required
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row>
                {productType != 1 ? (
                  <Col span={8}>
                    <Form.Item
                      label="商品类目"
                      required
                      name="product_category_id"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select placeholder="请选择">
                        {productCategroy.map((i) => {
                          return (
                            <Option value={i.id} key={i.id}>
                              {i.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                ) : null}

                <Col span={8}>
                  <Form.Item
                    label="每订单限购"
                    labelAlign="right"
                    className="orderlimit"
                    name="order_limit"
                    required
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                {productType == 1 && (
                  <Col span={8}>
                    <Form.Item
                      label="核销次数"
                      name="total_write_off_count"
                      required
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputNumber precision={0} />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Col>

            <Col span={24}>
              <Row>
                <Col span={8}>
                  <Form.Item
                    label="平台分类"
                    name="category_ids"
                    required
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Cascader
                      options={
                        categoryRes.data ? categoryRes.data.categorys : []
                      }
                      // onChange={(v)=>{console.log(v);}}
                    />
                  </Form.Item>
                </Col>
                {isSupplier ? (
                  <Col span={8}>
                    <Form.Item label="供应商" name="supplier_id" labelAlign="right">
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {(supplyChainData?.suppliers?.data || []).map((i) => {
                          return (
                            <Option value={i.id} key={i.id}>
                              {i.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                ) : null}
              </Row>
            </Col>

            <Col span={24}>
              <Row>
                <Col span={8}>
                  <Form.Item
                    label="商品卖点"
                    name="sales_point"
                    required
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <TextArea
                      rows={4}
                      maxLength={50}
                      placeholder={`最多${50}个字`}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="搜索关键词" name="keywords" labelAlign="right">
                    <Select
                      mode="tags"
                      style={{ width: '100%' }}
                      tokenSeparators={[',']}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {Cookies.get("is_spend") == 1 && (
              <Col span={12}>
                <Form.Item label="分佣设置">
                  <Row>
                    <Col span={24}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Form.Item
                          label="让利佣金"
                          name="pusherman_redpack_rate"
                          style={{
                            marginBottom: 0,
                            marginRight: 5,
                          }}
                          rules={[
                            {
                              validator: (_, v, cb) => {
                                try {
                                  if (isNaN(+v)) {
                                    return Promise.reject(
                                      new Error("分佣必须为数字")
                                    );
                                  }
                                  if (`${v}`.split(".")[1]) {
                                    return Promise.reject(
                                      new Error("分佣必须为整数")
                                    );
                                  }
                                  if (+v < 0 || +v > 100) {
                                    return Promise.reject(
                                      new Error("分佣必须为0-100整数")
                                    );
                                  }
                                  cb();
                                } catch (error) {
                                  cb(error);
                                }
                              },
                            },
                          ]}
                        >
                          <Input placeholder="请输入0-100整数" />
                        </Form.Item>
                        <span>%</span>
                      </div>
                    </Col>
                    {/* <Col span={2} />
                    <Col span={10} flex>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Form.Item
                          label="服务中心"
                          name="service_redpack_rate"
                          style={{
                            marginBottom: 0,
                            marginRight: 5,
                          }}
                          rules={[
                            {
                              validator: (_, v, cb) => {
                                try {
                                  if (isNaN(+v)) {
                                    return Promise.reject(
                                      new Error("分佣必须为数字")
                                    );
                                  }
                                  if (`${v}`.split(".")[1]) {
                                    return Promise.reject(
                                      new Error("分佣必须为整数")
                                    );
                                  }
                                  if (+v < 0 || +v > 100) {
                                    return Promise.reject(
                                      new Error("分佣必须为0-100整数")
                                    );
                                  }
                                  cb();
                                } catch (error) {
                                  console.error(v, "vvv");
                                  cb(error);
                                }
                              },
                            },
                          ]}
                        >
                          <Input placeholder="请输入0-100整数" />
                        </Form.Item>
                        <span>%</span>
                      </div>
                    </Col> */}
                    <span>
                      温馨提示：设置比例按照实际零售价比例奥～
                    </span>
                  </Row>
                </Form.Item>
              </Col>
            )}

            <Col span={24}>
              <Row>
                <Col span={22}>
                  <Form.Item
                    label="商品描述"
                    name="content"
                    required
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <BraftEditor
                      style={{ border: "1px solid #ddd" }}
                      media={{
                        uploadFn: myUploadFn,
                        accepts: {
                          image:
                            "image/png,image/jpeg,image/gif,image/webp,image/apng,image/svg",
                          video: false,
                          audio: false,
                        },
                      }}
                      onChange={handleEditorChange}
                      imageControls={[
                        "link", // 设置图片超链接
                        "size", // 设置图片尺寸
                        "remove", // 删除图片
                      ]}
                      value={content}
                    />
                    <div
                      style={{
                        color: "#999",
                        fontSize: 14,
                        marginTop: 20,
                      }}
                    >
                      操作提示：请留意光标所在位置，不支持上传视频，每一行仅能上传一张图片，建议图片宽度为750px
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row>
                <Col span={8}>
                  <Form.Item label="是否押金商品" name="is_deposit">
                    <Switch
                      checkedChildren="开"
                      unCheckedChildren="关"
                      checked={isAudit == 1 ? true : false}
                      onChange={changeAudit}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row>
                <Col span={8}>
                  <Form.Item label="是否上架" name="status">
                    <Switch
                      checkedChildren="开"
                      unCheckedChildren="关"
                      checked={status == 1 ? true : false}
                      onChange={changeSwi}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            {productType === 0 && (
              <Col span={24}>
                <Row>
                  <Col span={8}>
                    <Form.Item label="是否必点" name="is_must">
                      <Switch
                        checkedChildren="开"
                        unCheckedChildren="关"
                        checked={ismust == 1 ? true : false}
                        onChange={changeSwitwo}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}

            {/* {isSpend == 1 ? (
              <Col span={24}>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="生效模块"
                      name="module_type"
                      required
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Radio.Group style={{ width: "100%" }}>
                        <Radio value={1}>
                          官方商城商品（399、3999等商品，用户购买可成为代理商）
                        </Radio>
                        <br></br>
                        <Radio value={2}>
                          特推商城商品（官方自营商城商品）
                        </Radio>
                        <br></br>
                        <Radio value={3}>
                          红包券商城商品（新人优惠红包商城）
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            ) : null} */}

            {Cookies.get("is_spend") == 1 && (
              <Col span={24}>
                <Form.Item label="单独赏金计划比例">
                  <Form.Item name="commission_rate" noStyle>
                    <Input
                      type="number"
                      placeholder="请输入1-100的赏金计划比例"
                      style={{ width: 300 }}
                    />
                  </Form.Item>
                  <div className="tip2" style={{ margin: 0 }}>
                    ({"单个商品设置单独的赏金计划比例N后(0<N<100%)，此商品的赏金计划=此商品订单实付金额*N"})
                  </div>
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <div className="product-info_title bold">图片</div>
            </Col>

            <Modal
              title="请选择"
              width={800}
              visible={showSelect}
              onOk={() => handleOk()}
              onCancel={() => {
                setShowSelect(false);
              }}
            >
              <Radio.Group style={{ width: "100%" }} onChange={onChecked}>
                <Row>
                  {resource.map((i, index) => {
                    return (
                      <Col span={8} key={index}>
                        <Radio
                          value={
                            whoChoose == 0
                              ? i.video && i.video[0].url
                              : i.image && i.image[0].url
                          }
                        >
                          {whoChoose == 0 ? (
                            <video
                              muted
                              controls="controls"
                              style={{
                                width: "200px",
                                height: "200px",
                                marginBottom: "20px",
                              }}
                              src={i.video && i.video[0].url}
                            />
                          ) : (
                            <img
                              alt=""
                              style={{
                                width: "120px",
                                height: "120px",
                                marginBottom: "20px",
                              }}
                              src={i.image && i.image[0].url}
                            />
                          )}
                        </Radio>
                      </Col>
                    );
                  })}
                </Row>
              </Radio.Group>
            </Modal>
            <Col span={24}>
              <Col span={8}>
                <Form.Item label="视频" name="video">
                  <div style={{ display: "flex" }}>
                    <FileUpload
                      fileList={video}
                      OSSData={OSSData}
                      type={2}
                      listChange={changeVideo}
                    />
                    {video.length >= 1 ? null : (
                      <Button
                        style={{ marginLeft: 10 }}
                        onClick={() => getList(0)}
                      >
                        视频库选择
                      </Button>
                    )}
                  </div>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Col span={8}>
                  <Form.Item
                    label="封面"
                    className="formcover"
                    required
                    name="cover"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <div style={{ display: "flex" }}>
                      <FileUpload
                        needCrop
                        fileList={cover}
                        OSSData={OSSData}
                        listChange={changeCover}
                      />
                      {cover.length >= 1 ? null : (
                        <Button
                          style={{ marginLeft: 10 }}
                          onClick={() => getList(1)}
                        >
                          图库选择
                        </Button>
                      )}
                    </div>
                  </Form.Item>
                </Col>
              </Col>

              <Col span={24}>
                <Col span={24}>
                  <Form.Item
                    label="商品图片"
                    className="formimages"
                    name="images"
                    required
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <div style={{ display: "flex" }}>
                      <FileUpload
                        needCrop={false}
                        fileList={images}
                        OSSData={OSSData}
                        listChange={changeImages}
                        maxLen={5}
                        multiple={true}
                      />
                      {images.length >= 5 ? null : (
                        <Button
                          style={{ marginLeft: 10 }}
                          onClick={() => getList(2)}
                        >
                          图库选择
                        </Button>
                      )}
                    </div>
                  </Form.Item>
                </Col>
              </Col>
            </Col>
          </Row>
        </div>
        <div className="product-info productSpecs explain-no-margin">
          <div className="product-info_title-wrapper" style={{ marginBottom: 16 }}>
            <div className="product-info_title bold">主价格</div>
          </div>
          <Space size={[30, 0]} wrap style={{ display: 'flex' }}>
            <Form.Item
              label="销售价"
              name="price"
              rules={[
                {
                  required: true,
                }
              ]}
            >
              <Input type="number" placeholder="请输入销售价" style={{ width: 167 }} />
            </Form.Item>
            <Form.Item
              label="库存"
              name="stock"
              rules={[
                {
                  required: true,
                }
              ]}
            >
              <InputNumber min={0} placeholder="请输入库存" style={{ width: 167 }} />
            </Form.Item>
            <Form.Item
              label="划线价"
              name="first_price"
              rules={[
                {
                  validator(_, value){
                    if (!value || value == 0 || !data.price || Number(value) > Number(data.price)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('划线价必须大于销售价'));
                  }
                }
              ]}
            >
              <Input type="number" placeholder="请输入划线价" style={{ width: 167 }} />
            </Form.Item>
            <Form.Item
              label="供货价(元)"
              name="product_offer_price"
            >
              <Input type="number" placeholder="请输入供货价" style={{ width: 167 }} />
            </Form.Item>
            {(data.specs_type == 1 || specsData.length <= 1) && (
              <Form.Item label="条形码" name="barcode">
                <Input
                  placeholder="请输入商品条形码"
                  style={{ width: 167 }}
                  onPressEnter={(e) => {
                    e.preventDefault()
                  }}
                />
              </Form.Item>
            )}
          </Space>
          <Form.Item
            name="specs_type"
            rules={[
              {
                required: true,
                message: '请选择规格类型'
              }
            ]}
            initialValue={1}
          >
            <Radio.Group onChange={e => specsTypeChange(e.target.value)}>
              <Radio value={1}>单品</Radio>
              <Radio value={2}>多种规格类型</Radio>
            </Radio.Group>
          </Form.Item>
          {data.specs_type == 2 && (
            <>
              <div className="product-info_title-wrapper" style={{ marginBottom: 7, alignItems: 'center' }}>
                <div className="product-info_title bold">规格类型</div>
                <div
                  className="add"
                  onClick={addSpecs}
                >
                  <PlusOutlined />新增分类
                </div>
              </div>
              <Form.List name="productSpecs">
                {(fields, { remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <div key={key}>
                        <Space
                          align="center"
                          style={{ display: 'flex', marginBottom: 7 }}
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "name"]}
                            rules={[{ required: true, message: '' }]}
                            style={{ marginBottom: 0 }}
                          >
                            <Input placeholder={`请输入分类${name + 1}`} style={{ width: 185 }} />
                          </Form.Item>
                          {fields.length > 1 && <MinusCircleOutlined style={{ color: '#63626C' }} onClick={() => remove(name)} />}
                        </Space>
                        <Form.List name={[name, "list"]}>
                          {(fields2, { add: add2, remove: remove2 }) => (
                            <Space
                              size={[20,7]}
                              wrap
                              style={{
                                display: 'flex',
                                marginBottom: 15,
                                paddingLeft: 32
                              }}
                            >
                              {fields2.map(({ key: key2, name: name2, ...restField2 }) => (
                                <Space key={key2} align="center">
                                  <Form.Item
                                    {...restField2}
                                    name={[name2, "name"]}
                                    rules={[{ required: true, message: '' }]}
                                    style={{ marginBottom: 0 }}
                                  >
                                    <Input placeholder="请输入规格" style={{ width: 185 }} />
                                  </Form.Item>
                                  {fields2.length > 1 && <MinusCircleOutlined style={{ color: '#63626C' }} onClick={() => remove2(name2)} />}
                                </Space>
                              ))}
                              <div
                                className="add"
                                style={{
                                  marginLeft: 10
                                }}
                                onClick={() => add2({ name: '' })}
                              >新增规格</div>
                            </Space>
                          )}
                        </Form.List>
                      </div>
                    ))}
                  </>
                )}
              </Form.List>
              <div className="product-info_title-wrapper" style={{ marginBottom: 7 }}>
                <div className="product-info_title bold">商品规格</div>
              </div>
              <Table
                className="productSpecs-table"
                columns={specsColumns}
                dataSource={specsData}
                bordered
                pagination={false}
                scroll={{ x: '100%' }}
              />
            </>
          )}
        </div>
        {/* <div className="product-info">
          <div className="product-info_title-wrapper" style={{ marginBottom: 16 }}>
            <div className="product-info_title bold">关联热量信息</div>
          </div>
          <Form.Item
            label="关联菜品名"
            name="dishes_name"
            style={{ marginBottom: 15 }}
          >
            <Select
              mode="multiple"
              placeholder="请选择"
              showArrow
              allowClear
              options={dishesOptions}
              style={{ width: 'auto', minWidth: 188, maxWidth: '100%' }}
              onChange={(value, arr) => {
                const _list = data.dishes_list
                if (arr.length > 0) {
                  let val = []
                  if (!_list || _list.length === 0) {
                    val = arr.map(t => ({
                      id: t.value,
                      label: t.label,
                      value: 0
                    }))
                  } else {
                    val = arr.map(t => {
                      const item = _list.find(_ => _.id === t.value)
                      return item ? {...item} : {
                        id: t.value,
                        label: t.label,
                        value: 0
                      }
                    })
                  }
                  form.setFieldValue('dishes_list', val)
                } else {
                  form.setFieldValue('dishes_list', [])
                }
              }}
            />
          </Form.Item>
          {!!data.dishes_list && data.dishes_list.length > 0 && (
            <Space
                size={[30, 15]}
                wrap
                style={{ display: 'flex', marginBottom: 15 }}
            >
              {data.dishes_list.map((item, index) => (
                <Space align="center" key={item.id}>
                  <Form.Item
                    key={item.id}
                    label={item.label}
                    name={['dishes_list', index, 'value']}
                    rules={[{ required: true, message: '' }]}
                    style={{ marginBottom: 0 }}
                  >
                    <Input type="number" suffix="g" style={{ width: 167 }} />
                  </Form.Item>
                  <MinusCircleOutlined style={{ color: '#63626C' }} onClick={() => {
                    const _names = [...data.dishes_name]
                    const _list = [...data.dishes_list]
                    _names.splice(index, 1)
                    _list.splice(index, 1)
                    form.setFieldsValue({
                      dishes_name: _names,
                      dishes_list: _list
                    })
                  }} />
                </Space>
              ))}
            </Space>
          )}
          <Space size={[30, 0]} wrap>
            <div className="dishes-kcal">
              <span className="dot"></span>
              <span className="text">热量</span>
              <span>-</span>
            </div>
            <div className="dishes-kcal">
              <span className="dot blue"></span>
              <span className="text">碳水化合物</span>
              <span>-</span>
            </div>
            <div className="dishes-kcal">
              <span className="dot green"></span>
              <span className="text">蛋白质</span>
              <span>-</span>
            </div>
            <div className="dishes-kcal">
              <span className="dot yellow"></span>
              <span className="text">纤维素</span>
              <span>-</span>
            </div>
            <div className="dishes-kcal">
              <span className="dot orange"></span>
              <span className="text">脂肪</span>
              <span>-</span>
            </div>
          </Space>
        </div> */}
        <div className="product-info explain-no-margin">
          <div className="product-info_title-wrapper" style={{ marginBottom: 16 }}>
            <div className="product-info_title bold">吃饭餐券比例</div>
          </div>
          <Form.Item>
            <span style={{ color: '#1B1A1E', marginRight: 6 }}>商品价格 X</span>
            <Form.Item
              name="rebate_rate"
              rules={[
                { required: true, message: "请输入吃饭餐券比例" }
              ]}
              initialValue={0}
              noStyle
            >
              <InputNumber min={0} placeholder="请输入比例" style={{ width: 118 }} />
            </Form.Item>
            <span style={{ color: '#1B1A1E', marginLeft: 6 }}>% = 送吃饭餐券金额</span>
          </Form.Item>
          <Form.Item style={{ margin: 0 }}>
            <Form.Item
              name="product_coupon"
              valuePropName="checked"
              noStyle
            >
              <Switch />
            </Form.Item>
            <span style={{ color: '#1B1A1E', fontWeight: 500, marginLeft: 8 }}>开启</span>
            <span style={{ color: '#8B8B92', marginLeft: 2 }}>(开启则表示赠送餐券，关闭则默认不送餐券，比例填写0按店铺比例返，非0按商品比例返)</span>
          </Form.Item>
        </div>
        {productType == 0 && (
          <div className="product-info explain-no-margin">
            <div className="product-info_title-wrapper">
              <div className="product-info_title bold">物流信息</div>
              <div
                className="delete"
                onClick={() => {
                  props.history.push("/logistics");
                }}
              >
                设置
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              <Form.Item
                label="配送方式"
                name="delivery_type"
                required
                rules={[
                  {
                    validator: (_, v, cb) => {
                      try {
                        if (deliveryType == 3 && isSelfDelivery == 0) {
                          return Promise.reject(
                            new Error("请选择配送方式")
                          )
                        }
                        cb();
                      } catch (error) {
                        cb(error);
                      }
                    },
                  },
                ]}
              >
                {logistics.map(item => (
                  <Checkbox
                    key={item.value}
                    checked={item.value === deliveryType}
                    onChange={(e) => {
                      if (item.value === 2 && e.target.checked) {
                        setSelfDelivery(0)
                      }
                      setDeliveryType(e.target.checked ? item.value : 3)
                      form.validateFields(['delivery_type'])
                    }}
                  >{item.label}</Checkbox>
                ))}
                <Checkbox
                  checked={isSelfDelivery == 1}
                  disabled={selfDeliveryDisabled}
                  onChange={(e) => {
                    setSelfDelivery(e.target.checked ? 1 : 0)
                    form.validateFields(['delivery_type'])
                  }}
                >到店自取</Checkbox>
              </Form.Item>
            </div>
          </div>
        )}
        <Button
          style={{ width: 150, float: "right" }}
          type="primary"
          htmlType="submit"
        >
          提交
        </Button>
      </div>)}
    </Form>
  );
};

export default NewProduct2;

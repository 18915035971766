import React, { useEffect, useState } from 'react'
import { Button, Input, message, Modal, Select, Table } from 'antd'
import { getNumeralCouponList, setEditTime } from '@/apis/mealCoupon'
import './index.less'

const HeaderCell = [
    {
        title: '创建时间',
        dataIndex: 'created_at',
    },
    {
        title: '券名称',
        dataIndex: 'name',
    },
    {
        title: '券类型',
        dataIndex: 'type_html',
    },
    {
        title: '状态',
        dataIndex: 'status_html',
    },
    {
        title: '券时间周期',
        dataIndex: 'time',
    },
    {
        title: '券金额',
        dataIndex: 'price',
    },
    {
        title: '折扣比例',
        dataIndex: 'discount_ratio',
    },
    {
        title: '操作',
        dataIndex: 'optional',
    },
]

const statusOptions = [
    { value: 1, label: '未开始' },
    { value: 2, label: '已开始' },
    { value: 3, label: '已结束' },
]
const typeOptions = [
    { value: 1, label: '满减券' },
    { value: 2, label: '折扣券' },
    { value: 3, label: '代金券' },
]

const NumeralCoupon = (props) => {
    const { history } = props;
    const [status, setStatus] = useState()
    const [type, setType] = useState()
    const [keywords, setKeywords] = useState('')
    const [pageInfo, setPageInfo] = useState({
        page: 1,
        limit: 10,
    })
    const [total, setTotal] = useState(0)
    const [list, setList] = useState([])

    useEffect(() => {
        getList()
    }, [status, type, pageInfo])

    const getList = async() => {
        try {
            const { data } = await getNumeralCouponList({
                ...pageInfo,
                status,
                type,
                keywords
            })
            setList(data.data)
            setTotal(data.total)
        } catch (e) {
            console.log(e)
        }
    }

    const onChange = (page) => {
        setPageInfo({
            ...pageInfo,
            page
        })
    }

    // 新增
    const add = () => {
        history.push('/numeralCoupon/add');
    }

    //详情
    const preview = (id) => {
        history.push(`/numeralCoupon/add?id=${id}`);
    }

    const editTime = (id, type) => {
        Modal.confirm({
            content: `是否要${type === 1 ? '提前开始' : '提前结束'}？`,
            okText: '确定',
            onOk: async() => {
                try {
                    await setEditTime({ id, type })
                    message.success('操作成功')
                    getList()
                } catch (e) {
                    console.log(e)
                }
            },
        })
    }

    const optionalRender = (_, record) => {
        return (
            <div className="optional">
                {record.status === 1 && <span className="edit" onClick={() => editTime(record.id, 1)}>
                    提前开始
                </span>}
                {record.status === 2 && <span className="edit" onClick={() => editTime(record.id, 2)}>
                    提前结束
                </span>}
                <span className="preview" onClick={() => preview(record.id)}>
                    详情
                </span>
            </div>
        )
    }

    const columns1Getter = HeaderCell.map(col => {
        if (col.dataIndex === 'time') {
            col.render = (_, record) => {
                return `${record.start_time}—${record.end_time}`
            }
        } else if (col.dataIndex === 'optional') {
            col.render = optionalRender
        }
        col.align = 'center'
        return col
    })

    const TableProps = {
        columns: columns1Getter,
        dataSource: list,
        bordered: true,
        pagination: {
            current: pageInfo.page,
            position: ["bottomCenter"],
            defaultPageSize: pageInfo.limit,
            onChange: onChange,
            showSizeChanger: false,
            showQuickJumper: true,
            total,
            showTotal: (total) => `共${total}条`,
        },
        scroll: {
            x: "max-content",
            scrollToFirstRowOnChange: true,
        },
        rowKey: "id",
    }

    return (
        <div className="numeralCoupon-wrapper">
            <div style={{ marginBottom: 20 }}>
                <Button type="primary" onClick={add}>
                    新增数字券
                </Button>
                <Select
                    style={{
                        width: 120,
                        marginLeft: '10px',
                    }}
                    value={status}
                    allowClear
                    placeholder="请选择"
                    onChange={(v) => {
                        setStatus(v)
                        setPageInfo({
                            ...pageInfo,
                            page: 1,
                        })
                    }}
                >
                    {statusOptions.map((item) => (
                        <Select.Option value={item.value} key={item.label}>
                            {item.label}
                        </Select.Option>
                    ))}
                </Select>
                <Select
                    style={{
                        width: 120,
                        marginLeft: '10px',
                    }}
                    value={type}
                    allowClear
                    placeholder="请选择"
                    onChange={(v) => {
                        setType(v)
                        setPageInfo({
                            ...pageInfo,
                            page: 1,
                        })
                    }}
                >
                    {typeOptions.map((item) => (
                        <Select.Option value={item.value} key={item.label}>
                            {item.label}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div style={{ marginBottom: 20 }}>
                <Input
                    placeholder="请输入数字券名称"
                    style={{
                        width: 230,
                        marginRight: 10,
                    }}
                    value={keywords}
                    allowClear
                    onChange={({ target: { value } }) => setKeywords(value)}
                />
                <Button type="primary" onClick={() => onChange(1)}>
                    确定
                </Button>
            </div>
            <Table {...TableProps} />
        </div>
    )
}

export default NumeralCoupon
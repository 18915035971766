import http from '../request'

// 发放餐券记录
export const getCouponStatistic = () => http.get('/statistic/index')

// 发放餐券记录明细
export const getCouponGiveDetail = (params) => http.get('/statistic/give-detail', params)

// 发券使用记录
export const getCouponUsedList = () => http.get('/statistic/use')

// 发放餐券记录明细
export const getCouponUsedDetail = (params) => http.get('/statistic/use-detail', params)

// 餐券抵扣记录
export const getCouponDeduction = () => http.get('/statistic/deduction')

// 发放餐券记录明细
export const getCouponDeductionDetail = (params) => http.get('/statistic/deduction-detail', params)

// 可叠加券列表
export const getStackingList = (params) => http.get('/numeral-coupon/stacking-list', params)

// 获取标签列表
export const getCategoryList = (params) => http.get('/numeral-coupon/tag-list', params)

// 数字券添加
export const numeralCouponAdd = (params) => http.post('/numeral-coupon/add', params)

// 数字券活动提前开始 / 提前结束
export const setEditTime = (params) => http.post('/numeral-coupon/edit-time', params)

// 店铺列表查询
export const getStoreList = (params) => http.get('/numeral-coupon/store-list', params)

// 商家后台-数字券列表
export const getNumeralCouponList = (params) => http.get('/numeral-coupon/list', params)

// 商家后台-查看详情
export const getNumeralCouponDetail = (params) => http.get('/numeral-coupon/detail', params)
